// Heading
//
// Styleguide Elements - Heading


// Default
//
// Standard headings for hierarchical content.
//
// Markup:
// <h1>Heading 1</h1>
// <h2>Heading 2</h2>
// <h3>Heading 3</h3>
// <h4>Heading 4</h4>
// <h5>Heading 5</h5>
// <h6>Heading 6</h6>
//
// Styleguide Elements - Heading - Default

h1 { @include heading(h1); }
h2 { @include heading(h2); }
h3 { @include heading(h3); }
h4 { @include heading(h4); }
h5 { @include heading(h5); }
h6 { @include heading(h6); }


// Mimic
//
// Allow non-semantic elements to look like standard headings.
//
// Markup:
// <div class="h1">Heading 1</div>
// <div class="h2">Heading 2</div>
// <div class="h3">Heading 3</div>
// <div class="h4">Heading 4</div>
// <div class="h5">Heading 5</div>
// <div class="h6">Heading 6</div>
//
// Styleguide Elements - Heading - Mimic

.h1 { @include heading(h1); }
.h2 { @include heading(h2); }
.h3 { @include heading(h3); }
.h4 { @include heading(h4); }
.h5 { @include heading(h5); }
.h6 { @include heading(h6); }

h1, h2, h3, h4, h5, h6 {
  &:focus {
    outline: none;
  }
}
